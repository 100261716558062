/* global document */
import $ from 'jquery';
import accordionjs from 'accordionjs';

$(document).ready(function(){
    var accordion = $(".accordion__list");

    if(accordion){
        accordion.accordionjs({
            activeIndex: false,
            closeAble: true,
            closeOther: true,
            slideSpeed: 350
        });
    }
});