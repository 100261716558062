/* global document window history location */

document.addEventListener('DOMContentLoaded', function(){

  if (document.querySelector(".tabs")) {

    const tabsLinksPC = document.querySelectorAll(".tabs__links"),
          tabsLinksMOB = document.querySelectorAll(".tabs__dropdown"),
          tabsDropdown = document.querySelector(".tabs__dropdown"),
          tabsDropdownBtn = tabsDropdown.querySelector(".tabs__dropdown-btn span"),
          tabsDropdownList = tabsDropdown.querySelector(".tabs__dropdown-list");

    if (screen.width >= 1172) {
      if (tabsLinksMOB.length > 0) {
        tabsLinksMOB.forEach((item) => {
          item.remove();
        });
      }
    } else {
      if (tabsLinksPC.length > 0) {
        tabsLinksPC.forEach((item) => {
          item.remove();
        });
      }

      initTabsDropdownBtn();
      
      tabsDropdown.addEventListener("click", (e) => {
        if (e.target.classList.contains("tabs__dropdown-btn") || e.target.classList.contains("tabs__dropdown-btn-text")) {
          tabsDropdownList.classList.toggle("active");
        }

        if (e.target.classList.contains("tabs__link")) {
          tabsDropdownBtn.textContent = e.target.textContent;
        }
      });
    }

    if(location.hash) {
      showTab(location.hash);

      if (screen.width < 1172) {
        initTabsDropdownBtn();
      }
    }

    // Следим за поднимающимися кликами
    document.addEventListener('click', function(event) {
      if(event.target.dataset.toggle === 'tab') {
        event.preventDefault();
        var target = event.target.hash === undefined ? event.target.dataset.target : event.target.hash;
        if ( target !== undefined ) {
          showTab(target);
          if(history && history.pushState && history.replaceState) {
            var stateObject = {'url' : target};
            if (window.location.hash && stateObject.url !== window.location.hash) {
              window.history.pushState(stateObject, document.title, window.location.pathname + target);
            } else {
              window.history.replaceState(stateObject, document.title, window.location.pathname + target);
            }
          }
        }
      }

      if( !(event.target.classList.contains("tabs__dropdown-btn") || event.target.classList.contains("tabs__dropdown-btn-text")) ) {
        tabsDropdownList.classList.remove("active");
      }
    });

    /**
     * Показывает таб
     * @param  {string} tabId ID таба, который нужно показать
     */
    function showTab(tabId){
      var element = document.querySelector(tabId);
      if ( element && element.classList.contains('tabs__content-item') ) {
        var tabsParent = document.querySelector(tabId).closest('.tabs');
        var activeTabClassName = 'tabs__link-wrap--active';
        var activeTabContentClassName = 'tabs__content-item--active';
        // таб
        tabsParent.querySelectorAll('.'+activeTabClassName).forEach(function(item){
          item.classList.remove(activeTabClassName);
        });
        var activeTab = tabsParent.querySelector('[href="'+tabId+'"]') ? tabsParent.querySelector('[href="'+tabId+'"]') : tabsParent.querySelector('[data-target="'+tabId+'"]')
        activeTab.closest('.tabs__link-wrap').classList.add(activeTabClassName);
        // контент таба
        tabsParent.querySelectorAll('.'+activeTabContentClassName).forEach(function(item){
          item.classList.remove(activeTabContentClassName);
        });
        tabsParent.querySelector(tabId).classList.add(activeTabContentClassName);
      }
    }

    function initTabsDropdownBtn() {
      tabsDropdownBtn.textContent = tabsDropdown.querySelector(".tabs__link-wrap--active .tabs__link").textContent;
    }

  }

});
