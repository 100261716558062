// /* global document console */

import ready from 'Utils/documentReady.js';
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { Fancybox } from "@fancyapps/ui";
import getScrollSize from 'Utils/getScrollSize.js';

Swiper.use([Navigation, Pagination, EffectFade]);

ready(function() {
  const doc = document,
        body = doc.querySelector('body');

  new Swiper(".top-slider__inner", {
    effect: "fade",
    speed: 1000,
    simulateTouch: false,
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
      formatFractionCurrent: function (number) {
        return `0${number}`;
      },
      formatFractionTotal: function (number) {
        return `0${number}`;
      },
      renderFraction: function (currentClass, totalClass) {
        return `<span class="${currentClass}"></span><span class="${totalClass}"></span>`;
      }
    },
  });

  new Swiper(".swiper-partners .swiper", {
    slidesPerView: 3,
    spaceBetween: 32,
    speed: 1000,
    navigation: {
      prevEl: ".swiper-partners .swiper-button-prev",
      nextEl: ".swiper-partners .swiper-button-next",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 22,
      },
      1172: {
        slidesPerView: 3,
        spaceBetween: 32,
      }
    }
  });

  flatpickr.localize(flatpickr.l10ns.ru);

  // const eventsCalendar =  flatpickr(".calendar__input", {
  //   inline: true,
  // });

  flatpickr(".input-date__input", {
    dateFormat: "d.m.Y",
    disableMobile: "true"
  });


  let burger = doc.querySelectorAll('.burger');

  if(burger.length > 0){
    let mobileMenu = doc.querySelector('.mobile-menu');

    burger.forEach(item => {
      item.addEventListener('click', ()=>{
        body.classList.toggle('of-h');
        mobileMenu.classList.toggle('active');
      });
    });
  }

  let tabAreas = document.querySelectorAll('.tabs-area'),
    tabAreaBtns, tabAreaBlocks;

  for (var i = 0; i < tabAreas.length; i++) {
    tabAreas[i].addEventListener('click', function(e) {
      if (e.target.classList.contains('tab-btn') || e.target.classList.contains('tab-btn-txt')) {
        tabAreaBtns = this.querySelectorAll('.tab-btn');
        tabAreaBlocks = this.querySelectorAll('.tab-block');
        clearItems(tabAreaBtns);
        clearItems(tabAreaBlocks);
        if (e.target.classList.contains('tab-btn-txt')) {
          e.target.parentElement.classList.add('active');
          this.querySelector('.tab-block[data-tab="'+e.target.parentElement.dataset.tab+'"]').classList.add('active');
        } else {
          e.target.classList.add('active');
          this.querySelector('.tab-block[data-tab="'+e.target.dataset.tab+'"]').classList.add('active');
        }        
      }
    });
  }

  function clearItems(array) {
    for (i = 0; i < array.length; i++)
      array[i].classList.remove('active');
  }

  Fancybox.bind("[data-fancybox]", {
    dragToClose: false,
  });

  // Добавление кастомного свойства с системной шириной скролла
  document.documentElement.style.setProperty('--css-scroll-size', `${getScrollSize()}px`);
});

// import $ from 'jquery'; // Перед использованием установить как зависимость
// $(function() {
//   console.log('jQuery героически сработал!');
// });
